// import {useEffect, useState} from 'react';
// import {useIntl} from 'react-intl';
// import {Form, Select, Switch, Input, Button} from 'antd';
// import {releaseTypes} from '../../../../util/letters';
// import {tailFormItemLayout} from '../../../../util/basic';
import SongList from './SongList';
import {useAuthUser} from '@crema/utility/AuthHooks';

const ArtistInfo = () => {
  // const {messages} = useIntl();
  const {user} = useAuthUser();
  // const [isBand, setIsBand] = useState(false);
  // const [form] = Form.useForm();
  // console.log('data', user);
  // const onFinish = (values) => {
  //   console.log('Success:', values);
  // };
  // const onFinishFailed = (errorInfo) => {
  //   console.log('Failed:', errorInfo);
  // };
  // useEffect(() => {
  //   const value = {
  //     artist: {
  //       type: user?.type,
  //       release_type: user?.release_type,
  //     },
  //   };
  //   form.setFieldsValue(value);
  // }, [form]);
  return (
    <>
      {/* <Form
        form={form}
        layout='horizontal'
        initialValues={{remember: true}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{xs: 24, md: 8}}
        wrapperCol={{xs: 24, md: 8}}>
        <Form.Item
          label={messages['common.artistType']}
          name={['affiliation', 'type']}
          className='form-field'
          rules={[{required: true, message: 'Please input Type!'}]}>
          <Select placeholder={messages['common.artistType']}>
            <Select.Option value={'C'}>Шүлэг / Author </Select.Option>
            <Select.Option value={'A'}>Ая / Composer</Select.Option>
            <Select.Option value={'AC'}>Шүлэг, Ая / Both</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={messages['common.releaseType']}
          name={['affiliation', 'release_type']}
          className='form-field'
          rules={[{required: true, message: 'Please input Release Type!'}]}>
          <Select
            placeholder={messages['common.releaseType']}
            mode='multiple'
            options={releaseTypes}></Select>
        </Form.Item>
        <Form.Item
          name={['affiliation', 'stage_name']}
          label={messages['common.stageName']}
          className='form-field'>
          <Input placeholder={messages['common.stageName']} />
        </Form.Item>
        <Form.Item
          label={messages['common.isBandMember']}
          name={['affiliation', 'is_band_member']}
          className='form-field'>
          <Switch
            checkedChildren={messages['common.yes']}
            unCheckedChildren={messages['common.no']}
            style={{width: 72}}
            onChange={(e) => setIsBand(e)}
          />
        </Form.Item>
        {isBand == true ? (
          <Form.Item
            label={messages['common.band']}
            name={['affiliation', 'band_name']}
            className='form-field'>
            <Input placeholder={messages['common.band']} value='' />
          </Form.Item>
        ) : (
          <></>
        )}
        <Form.Item {...tailFormItemLayout} className='user-profile-group-btn'>
          <Button type='primary' htmlType='submit'>
            Save Changes
          </Button>
          <Button htmlType='cancel'>Cancel</Button>
        </Form.Item>
      </Form> */}
      <SongList datas={user} />
    </>
  );
};

export default ArtistInfo;
