import ArtistInfo from 'pages/profile/UserProfile/ArtistInfo';
import BankInformation from 'pages/profile/UserProfile/BankInformation';
import ChangePassword from 'pages/profile/UserProfile/ChangePassword';
import Contact from 'pages/profile/UserProfile/Contact';
import Heirinfo from 'pages/profile/UserProfile/Heir';
import Manager from 'pages/profile/UserProfile/Manager';
import PersonalInfo from 'pages/profile/UserProfile/PersonalInfo';
import SocialLink from 'pages/profile/UserProfile/SocialLink';
import {RoutePermittedRole} from 'shared/constants/AppEnums';

export const artistConfig = [
  {
    path: '/profile',
    permittedRole: [RoutePermittedRole.artist, RoutePermittedRole.publisher],
    element: <PersonalInfo />,
  },
  {
    path: '/bank-info',
    permittedRole: [RoutePermittedRole.artist, RoutePermittedRole.publisher],
    element: <BankInformation />,
  },
  {
    path: '/emergency-contact',
    permittedRole: [RoutePermittedRole.artist, RoutePermittedRole.publisher],
    element: <Contact />,
  },
  {
    path: '/artist-info',
    permittedRole: [RoutePermittedRole.artist],
    element: <ArtistInfo />,
  },
  {
    path: '/manager-info',
    permittedRole: [RoutePermittedRole.artist],
    element: <Manager />,
  },
  {
    path: '/heir-info',
    permittedRole: [RoutePermittedRole.artist, RoutePermittedRole.publisher],
    element: <Heirinfo />,
  },
  {
    path: '/heir-info',
    permittedRole: [RoutePermittedRole.artist, RoutePermittedRole.publisher],
    element: <Heirinfo />,
  },
  {
    path: '/social-info',
    permittedRole: [RoutePermittedRole.artist, RoutePermittedRole.publisher],
    element: <SocialLink />,
  },
  {
    path: '/password-info',
    permittedRole: [RoutePermittedRole.artist, RoutePermittedRole.publisher],
    element: <ChangePassword />,
  },
];

// profile -> huwiin medeelel
// artist info
