import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ARTIST_LIST,
  GET_ARTIST_ITEM,
  ADD_NEW_ARTIST,
  NULLIFY_ARTIST,
  DELETE_ARTIST,
  SHOW_MESSAGE,
  UPDATE_ARTIST_STARRED_STATUS,
  GET_ARTIST_FOLDER_LIST,
  UPDATE_ARTIST_DETAIL,
  UPDATE_ARTIST_LABEL,
} from '../../shared/constants/ActionTypes';
import Api from '../../@crema/services/auth/jwt-auth/jwt-api';
import {appIntl} from '../../@crema/utility/helper/Utils';

export const onGetArtistList = (search, page, per_page) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/artists', {
      params: {search, page, per_page},
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ARTIST_LIST, payload: response.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: 'Something went wrong, Please try again!',
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetSelectedArtist = (id) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get(`/artists/${id}`)
      .then((data) => {
        console.log('onGetSelectedMail: ', data.data);
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ARTIST_ITEM, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onAddArtist = (data) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.post('/artists', {...data})
      .then((response) => {
        if (response.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: ADD_NEW_ARTIST, payload: response.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onNullifyArtist = () => {
  return {
    type: NULLIFY_ARTIST,
  };
};

export const onDeleteArtists = (type, name, artistIds, page) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.post('/api/artistApp/delete/artist', {type, name, artistIds, page})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: DELETE_ARTIST, payload: data.data});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.artistDeleted'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateStarredStatusForArtist = (
  artistIds,
  status,
  folderName,
) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.put('/api/artistApp/update/starred', {artistIds, status})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: UPDATE_ARTIST_STARRED_STATUS,
            payload: {data: data.data, folderName: folderName},
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.starredStatus'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onGetFolderListForArtist = () => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.get('/api/artistApp/folders/list')
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: GET_ARTIST_FOLDER_LIST, payload: data.data});
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateSelectedArtist = (artist) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.put('/api/artistApp/artist/', {artist})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({type: UPDATE_ARTIST_DETAIL, payload: data.data});
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.artistUpdated'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};

export const onUpdateArtistLabel = (artistIds, type, labelName) => {
  const {messages} = appIntl();
  return (dispatch) => {
    dispatch({type: FETCH_START});
    Api.put('/api/artistApp/update/label', {artistIds, type})
      .then((data) => {
        if (data.status === 200) {
          dispatch({type: FETCH_SUCCESS});
          dispatch({
            type: UPDATE_ARTIST_LABEL,
            payload: {data: data.data, labelName: labelName, labelType: type},
          });
          dispatch({
            type: SHOW_MESSAGE,
            payload: messages['message.labelUpdated'],
          });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: messages['message.somethingWentWrong'],
          });
        }
      })
      .catch((error) => {
        dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
