import {Button, Divider, Form, InputNumber, Select} from 'antd';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {banks} from 'util/letters';
import {tailFormItemLayout} from 'util/basic';
import {useAuthUserBankData} from '@crema/utility/AuthHooks';

const BankInformation = () => {
  const {bank} = useAuthUserBankData();
  const {messages} = useIntl();
  const [form] = Form.useForm();
  console.log('bank', bank);
  const onFinish = (values) => {
    console.log('Success:', values);
  };
  useEffect(() => {
    form.setFieldsValue({...bank});
  }, [bank]);
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      className='user-profile-form'
      initialValues={{remember: true}}
      onFinish={onFinish}
      labelCol={{xs: 24, md: 8}}
      wrapperCol={{xs: 24, md: 8}}
      layout='horizontal'
      onFinishFailed={onFinishFailed}>
      <Form.Item name={'name'} label={messages['common.bank']}>
        <Select placeholder={messages['common.bank']} options={banks}></Select>
      </Form.Item>
      <Form.Item
        label={messages['common.bankAccount']}
        name={'account'}
        rules={[
          {
            required: true,
            message: messages['validation.bankAccountNumber'],
          },
        ]}>
        <InputNumber
          style={{width: '100%'}}
          placeholder={messages['common.bankAccount']}
        />
      </Form.Item>
      <Form.Item {...tailFormItemLayout} className='user-profile-group-btn'>
        <Button type='primary' htmlType='submit'>
          {messages['common.saveChanges']}
        </Button>
        <Button htmlType='cancel'>Cancel</Button>
      </Form.Item>
      <Divider orientation='left'>Гүйлгээний түүх</Divider>
    </Form>
  );
};
export default BankInformation;
