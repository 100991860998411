export const name = (artist) => {
  const {first_name, last_name} = artist;
  return `${first_name?.charAt(0)?.toUpperCase()}.${
    last_name?.charAt(0)?.toUpperCase() + last_name?.slice(1)
  }`;
};

export const artistType = (user) => {
  const {type} = user?.artist;
  return type === 'C' ? 'Хөгжмийн зохиолч' : 'Яруу найрагч';
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
