import {
  GET_ARTIST_LIST,
  GET_ARTIST_ITEM,
  NULLIFY_ARTIST,
} from '../../shared/constants/ActionTypes';

const initialState = {
  artistData: {},
  selectedArtist: null,
  totalArtists: 0,
};

const artistReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTIST_LIST:
      return {
        ...state,
        artistData: action.payload,
        totalArtists: action.payload?.data.length,
      };
    case GET_ARTIST_ITEM: {
      return {
        ...state,
        selectedArtist: action.payload,
      };
    }
    case NULLIFY_ARTIST: {
      return {
        ...state,
        selectedArtist: null,
      };
    }
    default:
      return state;
  }
};
export default artistReducer;
