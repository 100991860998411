import React from 'react';
import ContactList from './ContactList/index';
import {useAuthUserContacts} from '@crema/utility/AuthHooks';

const Contact = () => {
  const {contacts} = useAuthUserContacts();
  return <ContactList list={contacts} />;
};

export default Contact;
