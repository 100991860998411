import React from 'react';
import {Button, Form, Input} from 'antd';
import IntlMessages from '../../../../@crema/utility/IntlMessages';
import {tailFormItemLayout} from 'util/basic';

const ChangePassword = () => {
  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      layout='horizontal'
      className='user-profile-form'
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{xs: 24, md: 8}}
      wrapperCol={{xs: 24, md: 8}}>
      <h3 className='user-profile-form-title'>
        <IntlMessages id='userProfile.changePassword' />
      </h3>
      <Form.Item
        name='oldPassword'
        label='Old password'
        rules={[{required: true, message: 'Please input your Enter Password'}]}>
        <Input.Password placeholder='Enter password' />
      </Form.Item>
      <Form.Item
        name='password'
        label='New password'
        rules={[{required: true, message: 'Please input your New Password!'}]}>
        <Input.Password placeholder='Enter new password' />
      </Form.Item>
      <Form.Item
        name='confirmPassword'
        label='Confirm password'
        rules={[
          {required: true, message: 'Please input Your Confirm Password!'},
          ({getFieldValue}) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                'The Confirm Password that you entered do not match!',
              );
            },
          }),
        ]}>
        <Input.Password placeholder='Confirm new password' />
      </Form.Item>
      <Form.Item {...tailFormItemLayout} className='user-profile-group-btn'>
        <Button type='primary' htmlType='submit'>
          Save Changes
        </Button>
        <Button htmlType='cancel'>Cancel</Button>
      </Form.Item>
    </Form>
  );
};

export default ChangePassword;
