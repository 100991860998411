import {useIntl} from 'react-intl';
import {Form, Select, Input, InputNumber, Upload, Button} from 'antd';
import {citizens, lettersmn} from 'util/letters';
import {InfoCircleOutlined, UploadOutlined} from '@ant-design/icons';
import {tailFormItemLayout} from 'util/basic';
import {useAuthUserHeir} from '@crema/utility/AuthHooks';
import {useEffect} from 'react';
const prefixRegister = (
  <>
    <Form.Item name={['register', 'letter1']} noStyle initialValue={'А'}>
      <Select style={{width: 60}} options={lettersmn}></Select>
    </Form.Item>
    <Form.Item name={['register', 'letter2']} noStyle initialValue={'А'}>
      <Select style={{width: 70}} options={lettersmn}></Select>
    </Form.Item>
  </>
);

const Heirinfo = () => {
  const {messages} = useIntl();
  const {heir} = useAuthUserHeir();
  const [form] = Form.useForm();
  useEffect(() => {
    if (heir) {
      form.setFieldsValue(heir);
    }
  }, [form]);
  const onFinish = (values) => {
    console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      layout='horizontal'
      initialValues={{remember: true}}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{xs: 24, md: 8}}
      wrapperCol={{xs: 24, md: 8}}>
      <Form.Item
        label={messages['common.heirFirstName']}
        name={['first_name']}
        className='form-field'>
        <Input placeholder={messages['common.heirFirstName']} />
      </Form.Item>
      <Form.Item
        label={messages['common.heirName']}
        name={['last_name']}
        className='form-field'>
        <Input placeholder={messages['common.heirName']} />
      </Form.Item>
      <Form.Item
        label={messages['common.heirRegister']}
        name={['register_number']}
        className='form-field'>
        <Input
          addonBefore={prefixRegister}
          max={99999999}
          maxLength={8}
          type='number'
          placeholder={messages['common.heirRegister']}
        />
      </Form.Item>
      <Form.Item
        label={messages['common.heirWhos']}
        name={['type']}
        className='form-field'>
        <Select options={citizens}></Select>
      </Form.Item>
      <Form.Item
        label={messages['common.heirPhone']}
        name={['phone']}
        className='form-field'>
        <InputNumber
          style={{width: '100%'}}
          placeholder={messages['common.heirPhone']}
        />
      </Form.Item>
      <Form.Item
        label={messages['common.heirEmail']}
        name={['email']}
        className='form-field'>
        <Input
          style={{width: '100%'}}
          placeholder={messages['common.heirEmail']}
        />
      </Form.Item>
      <Form.Item
        label={messages['common.heirFile']}
        name={['file_path']}
        tooltip={{
          title: 'Өв залгамжлагчийн нотариатын баталгаа Файлаар',
          icon: <InfoCircleOutlined />,
        }}
        className='form-field'>
        <Upload
          action={`${process.env.REACT_APP_API_URL}/upload`}
          valuePropName='file'>
          <Button icon={<UploadOutlined />}>
            {messages['common.heirFileUpload']}
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item {...tailFormItemLayout} className='user-profile-group-btn'>
        <Button type='primary' htmlType='submit'>
          Save Changes
        </Button>
        <Button htmlType='cancel'>Cancel</Button>
      </Form.Item>
    </Form>
  );
};

export default Heirinfo;
