import {Table} from 'antd';
import PropTypes from 'prop-types';

const columns = [
  {
    title: '№',
    dataIndex: 'index',
    key: 'index',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'MOSCAP код',
    dataIndex: 'moscap_code',
    key: 'moscap_code',
  },
  {
    title: 'Дууны нэр',
    dataIndex: 'origin_name',
    key: 'name',
  },
  {
    title: 'Дууны нэр/Галиг/',
    dataIndex: 'english_name',
    key: 'origin_name',
  },
  {
    title: 'Хөгжмийн зохиолч',
    dataIndex: 'hugjmiin_zohiolch',
    key: 'hugjmiin_zohiolch',
  },
  {
    title: 'Яруу найрагч',
    dataIndex: 'yaruu_nairagch',
    key: 'yaruu_nairagch',
  },
  {
    title: 'Хамтлаг дуучин',
    dataIndex: 'hamtlag_duuchin',
    key: 'hamtlag_duuchin',
  },
  {
    title: 'Бүтээлийн төрөл',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Бүртгэгдсэн огноо',
    dataIndex: 'registered_at',
    key: 'registered_at',
  },
];
const SongList = (datas) => {
  console.log('songs', datas?.datas?.artist?.songs);
  return <Table columns={columns} dataSource={datas?.datas?.artist?.songs} />;
};

export default SongList;

SongList.propType = {
  datas: PropTypes.any,
};
