import React from 'react';
import ContactList from './ContactList/index';
import PropTypes from 'prop-types';
import {useAuthUser} from '@crema/utility/AuthHooks';

const Manager = () => {
  const {user} = useAuthUser();
  console.log('user2', user?.artist);
  return <ContactList list={user?.artist?.managers} />;
};

export default Manager;

Manager.propTypes = {
  data: PropTypes.any,
};
